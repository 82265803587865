import { useStyleConfig, useStyles } from "@chakra-ui/react";
import React from "react";
import Img1 from "./images/HEALTH-BG.jpg";
import Img2 from "./images/HEALTHGO-PNG.png";

const InnerPage = () => {
  const styles = {
    innerImg: {
      display: "flex",
      width: "23rem",
    },
    innerBox : {
        display: "flex",
        width: "90%",
        height: "40vh",
        alignItems:"center",
        justifyContent:"space-evenly",
        fontFamily:"poppins",
        fontWeight:"bold",
        fontSize:"1.3rem",
        overflow:"hidden"
    }
  }
  return (
    <div
      className="inners"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1",
        width: "100vw",
        marginTop:"20px",
        alignItems:"center"
      }}
    >
      <div
        className="inner1"
        style={styles.innerBox}
      >
        <div>
          <img alt="Healthgo1" src={Img2} style={styles.innerImg} />
        </div>
        <div>Quality Healthcare for all on Blockchain</div>
      </div>
      <div
        className="inner2"
        style={styles.innerBox}
      >
        <div>Health education for all on Blockchain</div>
        <div style={styles.innerImg}>
          <img alt="Healthgo2" src={Img2} />
        </div>
      </div>
    </div>
  );
};

export default InnerPage;
