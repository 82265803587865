import React, { useLayoutEffect, useState } from "react";
import axios from "axios";

import {
  Text,
  Input,
  Button,
  useBreakpointValue,
  IconProps,
  Icon,
  useColorMode,
  useToast,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  useDisclosure,
  ButtonGroup,
  PopoverArrow,
  PopoverCloseButton
} from "@chakra-ui/react";
import Iphone from "./images/65968-big.png";
import apple from "./images/apple.png";
import appleWhite from "./images/apple-white.png";
import android from "./images/android.png";
import "./App.css";

import InnerPage from "./InnerPage";
import fileDownload from "js-file-download";
import DeviceDetector from "device-detector-js";

const Page = () => {
  //     state variables ------------------------------------------------------------------
  const { colorMode, toggleColorMode } = useColorMode();
  const [fullname, setFullname] = useState();
  const [email, setEmail] = useState();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [disab, setDisab] = useState(false);

  const toast = useToast();
  const deviceDetector = new DeviceDetector();
  const myDevice = deviceDetector.parse(window.navigator.userAgent);
  //   console.log(myDevice);
  //   console.log(
  //     deviceDetector.parse(
  //       "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36"
  //     )
  //   );
  //   useLayoutEffect(() => {

  //   }, [])

  // functions handling -----------------------------------------------------------------------
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob"
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const handleSubmit = () => {
    axios
      .post("https://health-go.herokuapp.com/auth/waitlist", {
        name: fullname,
        email: email
      })
      .then((response) => {
        console.log(response);
        toast({
          title: "Awesome😇",
          position: "top-right",
          description: `we'll be in touch`,
          status: "success",
          duration: 4000,
          isClosable: true
        });
        onToggle();
      });
  };
  // styling -------------------------------------------------------------------------------------

  const styles = {
    firstHeader: {
      fontWeight: "bold",
      fontSize: "4rem",
      //   maxWidth:"80px",
      textTransform: "capitalize",
      textAlign: "Left",
      position: "absolute",
      fontFamily: "poppins",
      marginTop: "20vh",
      marginLeft: "10%"
    },
    textAndImage: {
      display: "flex",
      flexDirection: "row",
      overflowX: "hidden",
      flex: "1"
    }
  };

  return (
    <div
      className="stories"
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        width: "100%"
      }}
    >
      <div
        className="blur"
        style={{ display: "flex", position: "absolute", zIndex: "0" }}
      >
        <Blur position={"absolute"} top={-10} left={"0px"} />
      </div>
      <div className="firstTextAndImage" style={styles.textAndImage}>
        <div className="global" style={styles.firstHeader}>
          <div style={{ display: "flex" }}>A Global Portal</div>
          <div
            style={{
              display: "flex",
              fontSize: "3rem",
              zIndex: "2",
              position: "relative",
              textShadow: "2px 4px 10px #283845"
            }}
          >
            for General Healthcare Assistance
          </div>
        </div>

        <div
          className="imgBox"
          style={{
            display: "flex",
            position: "relative",
            height: "600px",
            maxHeight: "600px",
            overflowY: "scroll",
            // border:"10px solid black",
            overflowX: "hidden,",
            right: "-25%",
            width: "100%"
          }}
        >
          <img
            className="immm"
            style={{
              display: "flex",
              position: "absolute",
              top: "-9309px",
              left: "-9999px",
              right: "-9999px",
              bottom: "-9999px",
              margin: "auto"
              //   right: "-70px",
              // right:"-220px"
              // width:"800px"
            }}
            src={Iphone}
            alt="homePage"
          />
        </div>
      </div>
      <div
        className="downloads"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignSelf: "center",
          flexDirection: "row",
          width: "90%",
          position: "absolute",
          fontFamily: "poppins",
          zIndex: "4",
          marginTop: "min(400px, 600px)"
        }}
      >
        <Link
          href={
            myDevice.os.name === "Android" || myDevice.os.name === "Windows"
              ? "https://expo.dev/artifacts/eas/miqDdLDCh9xi2yngdms6Sx.apk"
              : ""
          }
          download={
            myDevice.os.name === "Android" || myDevice.os.name === "Windows"
              ? false
              : true
          }
          style={{ textDecoration: "none" }}
        >
          <Button
            colorScheme="teal"
            height={"fit-content"}
            padding={"10px"}
            variant="ghost"
            disabled={
              myDevice.os.name === "Android" || myDevice.os.name === "Windows"
                ? false
                : true
            }
            onClick={() => {
              toast({
                title: "Download starting",
                position: "top-right",
                description: `Your Download will begin right away`,
                status: "success",
                duration: 4000,
                isClosable: true
              });
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Text
                as={"span"}
                bgGradient="linear(to-r, #283845, #EF767A,)"
                // textShadow= {"2px 4px 10px #298640"}
                bgClip="text"
              >
                Download for Android now
              </Text>
              <div>
                <img src={android} style={{ display: "flex", width: "50px" }} />
              </div>
            </div>
          </Button>
        </Link>

        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          placement="bottom"
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Link
              href={
                myDevice.os.name === "iOS" ||
                (myDevice.os.name === "Mac" && disab === false)
                  ? "https://exp-shell-app-assets.s3.us-west-1.amazonaws.com/ios/%40codingalgorithm/HealthGO-af4ec269-387e-438f-9138-ba14c2cf39a5-simulator.tar.gz"
                  : ""
              }
              download={
                myDevice.os.name === "iOS" || myDevice.os.name === "Mac"
                  ? false
                  : true
              }
              style={{ textDecoration: "none" }}
            >
              <Button
                colorScheme="teal"
                height={"fit-content"}
                padding={"10px"}
                variant="ghost"
                disabled={
                  myDevice.os.name === "iOS" || myDevice.os.name === "Mac"
                    ? false
                    : true
                }
                onClick={() => {
                  toast({
                    title: "Download starting",
                    position: "top-right",
                    description: `Your Download will begin right away`,
                    status: "success",
                    duration: 4000,
                    isClosable: true
                  });
                  setTimeout(() => {
                    onToggle();
                    setDisab(true);
                  }, 3000);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Text
                    as={"span"}
                    bgGradient="linear(to-r, #283845, #EF767A,)"
                    // textShadow= {"2px 4px 10px #298640"}
                    bgClip="text"
                  >
                    Download for IOS now
                  </Text>
                  <div>
                    <img
                      src={colorMode === "dark" ? appleWhite : apple}
                      style={{ display: "flex", width: "50px" }}
                    />
                  </div>
                </div>
              </Button>
            </Link>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold">
              Awesome iOS users
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>we're all set🤩😊</PopoverBody>
            <PopoverBody
              style={{
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: "bold",
                marginTop: "-4%"
              }}
            >
              too bad Apple isn't😢
            </PopoverBody>
            {/* <Text >Let's stay in touch</Text> */}
            <PopoverBody>
              <Input
                style={{ fontFamily: "'Open Sans', sans-serif" }}
                placeholder="full name"
                onChange={(e) => setFullname(e.target.value)}
              />
            </PopoverBody>
            <PopoverBody>
              <Input
                style={{ fontFamily: "'Open Sans', sans-serif" }}
                placeholder="@email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                {/* <Button variant='outline'>Cancel</Button> */}
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Keep me posted
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
        }}
      >
        <InnerPage />
      </div>
    </div>
  );
};

export default Page;

export const Blur = ({ props }) => {
  return (
    <div style={{ display: "flex", marginLeft: "0px", filter: "blur(70px)" }}>
      <Icon
        width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
        zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
        height="560px"
        viewBox="0 0 528 560"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="71" cy="61" r="111" fill="#F56565" />
        <circle cx="244" cy="106" r="139" fill="#ED64A6" />
        <circle cy="291" r="139" fill="#ED64A6" />
        <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
        <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
        <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
        <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
      </Icon>
    </div>
  );
};
